import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { QualityIndicator, QualityIndicatorValueItem, SeriesParam } from '../model';
import { convertMinutes, PROCESS_COLORS, roundNumberPad2 } from '../utils/DataUtils';
import CharLoadingIcon from './CharLoadingIcon';
import styles from './stageline.module.scss';

let option: any;
option = {
  title: {
  },
  grid: {
    left: 15,
    right: 10,
    top: 0,
    bottom: 0,
    containLabel: true
  },
  xAxis: {
    type: 'time',
    axisTick: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: '#ffffff',
    },
    interval: 0,
  },
  series: [
    {
      markLine: {
        data: [
          {
            xAxis: 'NOW', // Set the X-axis point where you want the vertical line
          },
        ],
        lineStyle: {
          color: '#BDDFC5', // Line color
          type: 'solid', // Line style (solid, dashed, etc.)
        },
        symbol: 'none',
        label: {
          show: false,
        },
      },
      name: 'A',
      type: 'line',
      data: [100, 80, 65],
      itemStyle: {
        color: '#B3B3B3',
      },
      markArea: {
        itemStyle: {
          color: 'rgba(128, 128, 128, 0.3)', // Grey background with 30% opacity
        },
        data: [
          [
            {
              yAxis: 60,
            },
            {
              yAxis: 100,
            },
          ],
        ],
      },
      lineStyle: { color: '#B3B3B3' },
      markPoint: {
        symbol: 'pin',
        itemStyle: { color: '#B3B3B3' },
      },
    },
    {
      name: 'B',
      type: 'line',
      data: [120, 90, 28],
      itemStyle: {
        color: PROCESS_COLORS.FORECAST,
      },
      lineStyle: { color: PROCESS_COLORS.FORECAST },
    },
    {
      name: 'C',
      type: 'line',
      itemStyle: {
        color: '#14AE5C',
      },
      data: [null, null, 28, 90, 70, 85],
      lineStyle: { color: '#14AE5C' },
    },
    {
      name: 'D',
      type: 'line',
      itemStyle: {
        color: '#1492AE',
      },
      data: [null, null, 28, 80, 65, 40],
      lineStyle: { color: '#1492AE' },
    },
    {
      name: 'E',
      type: 'line',
      itemStyle: {
        color: '#E8B931',
      },
      data: [null, null, 28, 74, 25, 37],
      lineStyle: { color: '#E8B931' },
    },
  ],
};

const StageLineChart = ({
  childKey,
  indicatorData,
  hidden,
  forecastStepSeconds,
  simLoading
}: {
  childKey: string;
  indicatorData: QualityIndicator;
  hidden?: boolean;
  forecastStepSeconds: number;
  simLoading: boolean
}) => {
  const chartRef = useRef(null);
  // const [actualData, setActualData] = useState<QualityIndicatorValueItem[]>();
  const [measuredData, setMeasuredData] = useState<QualityIndicatorValueItem[]>(
    [],
  );
  const [optimalData, setOptimalData] = useState<QualityIndicatorValueItem[]>(
    [],
  );
  const [forecastData, setForecastData] = useState<QualityIndicatorValueItem[]>(
    [],
  );
  const [simulationData, setSimulationData] = useState<
    QualityIndicatorValueItem[]
  >([]);

  const [loading, setLoading] = useState(true);
  const nowRef = useRef(new Date());


  useEffect(() => {
    const fetchData = async () => {
      try {
        setMeasuredData(indicatorData.measuredData || []);
        setForecastData(indicatorData.forecastedData || []);
        setOptimalData(indicatorData.optimalData || []);
        setSimulationData(indicatorData.simulationData || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [indicatorData]);
  useCallback(
    (
      data: QualityIndicatorValueItem[],
      offets: number[],
      type?: 'before' | 'after',
    ) => {
      const missingDataOffsets = offets.filter(
        (offset) =>
          !data.some((item) => item.offset_seconds === offset) &&
          (type === 'before' ? offset <= 0 : offset >= 0),
      );
      missingDataOffsets.forEach((offset) => {
        data.push({
          offset_seconds: offset,
          offset_step: offset / forecastStepSeconds,
          timestamp: new Date().toISOString(),
          value: null as unknown as number,
        });
      });
    },
    [forecastStepSeconds],
  );

  function formatTimeHHMM(timestamp: string) {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  const mergeData = useCallback(
    (
      measuredData: QualityIndicatorValueItem[],
      forecastData: QualityIndicatorValueItem[],
      optimalData: QualityIndicatorValueItem[],
      simulationData: QualityIndicatorValueItem[],
    ) => {
      const allData = [
        ...measuredData,
        ...forecastData,
        ...optimalData,
        ...simulationData,
      ]

      const uniqueOffsets = Array.from(new Set(allData.map(item => item.offset_seconds)));
      uniqueOffsets.sort((a, b) => a - b);
      const interval =   Math.abs(uniqueOffsets[1] - uniqueOffsets[2]) / 60;


      const combinedData: any = {};

      const measuredNowData = measuredData.find(
        (item) => item.offset_seconds === 0,
      );
      if (measuredNowData) {
        if (forecastData.length > 0) {
          forecastData.push(measuredNowData);
        }
        if (optimalData.length > 0) {
          optimalData.push(measuredNowData);
        }
        if (simulationData.length > 0) {
          simulationData.push(measuredNowData);
        }
      }

      const now = new Date();
      [measuredData, forecastData, optimalData, simulationData].forEach(
        (data, index) => {
          data.forEach((item) => {
            if (!combinedData[item.offset_seconds]) {
              combinedData[item.offset_seconds] = {
                offset_step: item.offset_step,
                offset_seconds: item.offset_seconds,
                milliseconds: now.getTime() + item.offset_seconds * 1000,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
              };
            }
            combinedData[item.offset_seconds][`line${index + 1}`] = item.value;
          });
        },
      );
      const sortedData: any = Object.values(combinedData).sort(
        (a: any, b: any) => a.offset_seconds - b.offset_seconds,
      );
      const measuredLineData = sortedData.map((item: any) => [item.milliseconds, item.line1]);
      const forecastLineData = sortedData.map((item: any) => [item.milliseconds, item.line2]);
      const optimalLineData = sortedData.map((item: any) => [item.milliseconds, item.line3]);
      const simulationLineData = sortedData.map((item: any) => [item.milliseconds, item.line4]);
      return {
        measuredData: measuredLineData,
        forecastData: forecastLineData,
        optimalData: optimalLineData,
        simulationData: simulationLineData,
        interval: interval,
        uniqueOffsets: uniqueOffsets
      };
    },
    [],
  );

  useEffect(() => {
    setLoading(false);
    if (chartRef.current) {
      nowRef.current = new Date();
      const chartData = mergeData(
        measuredData,
        forecastData,
        optimalData,
        simulationData,
      );

      echarts.dispose(chartRef.current);
      let myChart = echarts.init(chartRef.current, null, { renderer: 'svg' });
      const element = chartRef.current;
      const resizeObserver = new ResizeObserver(() => {
          myChart.resize()
      });

      if (element) {
        resizeObserver.observe(element);
      }
      const actualSeries = {
        name: 'Actual',
        type: 'line',
        data: chartData.measuredData,
        itemStyle: {
          color: PROCESS_COLORS.ACTUAL,
        },
        markLine: {
          data: [
            {
              xAxis: new Date().getTime(), // Set the X-axis point where you want the vertical line
            },
          ],
          lineStyle: {
            color: '#BDDFC5', // Line color
            type: 'solid', // Line style (solid, dashed, etc.)
          },
          symbol: 'none',
          label: {
            show: true,
            formatter: 'NOW',
            position: 'start',
            color: '#ffffff',
            padding: [5,0 ,0,0],
            id: 'now'
          },
        },
        markArea: {
          itemStyle: {
            color: 'rgba(128, 128, 128, 0.3)',
          },
          data: [
            [
              {
                yAxis: indicatorData.target_range_min,
              },
              {
                yAxis: indicatorData.target_range_max,
              },
            ],
          ],
        },
        connectNulls: true,
        lineStyle: { color: PROCESS_COLORS.ACTUAL },
        markPoint: {
          symbol: 'pin',
          itemStyle: { color: PROCESS_COLORS.ACTUAL },
        },
        symbol: 'circle',
        symbolSize: 10,
      };
      const forecastSeries = {
        name: 'Forecast',
        type: 'line',
        data: chartData.forecastData,
        itemStyle: {
          color: PROCESS_COLORS.FORECAST,
        },
        connectNulls: true,
        lineStyle: { color: PROCESS_COLORS.FORECAST },
        symbol: 'circle',
        symbolSize: 10,
      };
      const optimalSeries = {
        name: 'Optimal',
        type: 'line',
        data: chartData.optimalData,
        itemStyle: {
          color: PROCESS_COLORS.OPTIMAL,
        },
        connectNulls: true,
        lineStyle: { color: PROCESS_COLORS.OPTIMAL },
        symbol: 'circle',
        symbolSize: 10,
      };
      const simulationSeries = {
        name: 'Simulation',
        type: 'line',
        data: chartData.simulationData,
        itemStyle: {
          color: PROCESS_COLORS.SIMULATION,
        },
        connectNulls: true,
        lineStyle: { color: PROCESS_COLORS.SIMULATION },
        symbol: 'circle',
        symbolSize: 10,
      };
      option.series = [actualSeries, forecastSeries, optimalSeries];
      if (simulationData?.length) {
        option.series.push(simulationSeries);
      }
      option.xAxis.boundaryGap = ['7%', '7%'];
      option.xAxis.axisLabel = {
        hideOverlap: true,
        padding: 0,
        interval: 0,
        margin: 10,
        color: '#ffffff',
        ellipsis: '...',
        overflow: 'truncate',
        showMinLabel: false,
        showMaxLabel: false,
        alignMinLabel: 'center',
        alignMaxLabel: 'center',
        formatter: function (value: any) {
          return formatTimeHHMM(value);
        }
      };
      option.tooltip = {
        trigger: 'axis',
        formatter: function (params: SeriesParam[]) {
          if (params[0]) {
            let result = `${convertMinutes(params[0].axisValue - nowRef.current.getTime())}<br/>`;
            params.forEach(function (item: SeriesParam) {
              if (
                item.value !== null &&
                item.value[1] !== null
              ) {
                result +=
                  item.marker +
                  item.seriesName +
                  ': ' +
                  roundNumberPad2(item.value[1]) +
                  '<br/>';
              }
            });
            return result;
          }
          return null;
        },
      };
      const customValues = new Set<number>();
      customValues.add(roundNumberPad2(indicatorData.target_range_min));
      customValues.add(roundNumberPad2(indicatorData.target_range_max));
      if(customValues.size > 1) {
        const findLongestNumber = (set: Set<number>): number =>
          Array.from(set).reduce((a, b) => a.toString().length > b.toString().length ? a : b);
        const padding = 15 + findLongestNumber(customValues).toString().length * 5;
        option.grid.right = `${padding}px`
      }
      option.yAxis = {
        type: 'value',
        position: 'right',
        min: indicatorData.value_range_min,
        max: indicatorData.value_range_max,
        interval: 0,
        splitLine: {
          lineStyle: {
            color: ['#aaa'],
          },
        },
        axisLabel: {
          show: true,
          color: '#ffffff',
          customValues: Array.from(customValues),
        },
      };

      option.title.show = false;
      myChart.setOption(option);
      myChart.resize()

      myChart.on('rendered', () => {
        const textElements = document.querySelectorAll(`#${childKey} text`);
        const nowElements = Array.from(textElements).filter(el => el.textContent === 'NOW');
        nowElements.forEach(nowElement => {
          const nowRect = nowElement.getBoundingClientRect();
          textElements.forEach(el => {
            if (el !== nowElement) {
              const rect = el.getBoundingClientRect();
              const ds = Math.abs(nowRect.left - rect.left);
              const de = Math.abs(nowRect.right - rect.right);
              if (ds <= 35 || de < 35) {
                (el as SVGTextElement).style.display = 'none';
              }
            }
          });
        });
      });


      return () => {
        if (element) {
          resizeObserver.unobserve(element);
        }
      };
    }
  }, [
    measuredData,
    forecastData,
    indicatorData,
    simulationData,
    mergeData,
    optimalData,
    childKey,
    nowRef
  ]);

  return (
    <div className={`relative ${styles.stageLineChartWraper} p-4 rounded-[8px]`}>
      {loading && (
        <div
          className={`absolute h-full w-full opacity-60 z-10 flex justify-center items-center`}
        >
          <CharLoadingIcon width={64} height={64}></CharLoadingIcon>
        </div>
      )}
      <div className={`text-white pb-1 flex`}>
        <span className={`flex font-bold`}>
          {indicatorData.name}
          {simLoading && (
            <CharLoadingIcon width={24} height={24}></CharLoadingIcon>
          )}
        </span>
        <span className={`ms-auto`}>
          {indicatorData.description}
        </span>
      </div>
      <div
        id={childKey}
        ref={chartRef}
        style={{
          width: '100%',
          minHeight: 150,
          visibility: hidden ? 'hidden' : 'visible',
        }}
      >
        <div className=" shadow rounded-md w-full">
          <div className="animate-pulse flex">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageLineChart;
